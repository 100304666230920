import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { values } from 'storage';

const Header = ({ title = '', onClose, subtitle }) => {
  const theme = values?.initObject?.theme;
  const headerClassName = `mbw-cart-popover-content__header mbw-cart-popover-content__header__theme__${theme}`;
  const headerTitleClassName = `mbw-cart-popover-content__header-title mbw-cart-popover-content__header-title__theme__${theme}`;
  const headerSubtitleClassName = `mbw-cart-popover-content__header-title mbw-cart-popover-content__header-title__theme__${theme}__subtitle`;

  return (
    <div className={headerClassName}>
      <div className='flex-container'>
        <div className={headerTitleClassName}>{title}</div>
        {subtitle != null && (
        <div className={headerSubtitleClassName}>
          {subtitle}
        </div>
        )}
      </div>
      <div onClick={onClose}>
        <CloseIcon className="mbw-cart-popover-content__header-close" />
      </div>
    </div>
  );
};

export default Header;

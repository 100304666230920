import React, { useContext, useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { CartItemsContext } from 'context';
import { values } from 'storage';
import { CurrenciesEnum } from 'enums';

import themesEnum from 'enums/themesEnum';

const deleteIcon =
  'https://s3-us-west-2.amazonaws.com/widget-cart.materialbank.com/assets/garbage.svg';

function customRound(num) {
  const number = Number(num);
  if (Number.isNaN(number)){
    return number;
  }
  // Check if the number has any digits after the decimal point
  if (Math.floor(number) !== number) {
    // If it has digits, round to 2 digits after the decimal point
    return number.toFixed(2);
  } else {
    // If it doesn't have digits, return the number as is
    return number;
  }
}

const CartItem = ({ material = {}, numberOfItemsAddedToCart, customClassName }) => {
  const { onDeleteItem, onIncreaseItem, onDecreaseItem } = useContext(CartItemsContext);
  const [itemMetadata, setItemMetadata] = useState({});
  const shouldShowPrice = values?.initObject?.showPricing;
  const showItemCount = values?.initObject?.showItemCount;
  const materialSamplePrice = material?.sample_price != null ? customRound(material?.sample_price) : null;
  const priceOverride = itemMetadata?.priceOverride != null ? customRound(Number(itemMetadata?.priceOverride)) : null;
  const samplePrice =
    shouldShowPrice && (priceOverride ?? materialSamplePrice ?? '0.00');

  const {
    name,
    thumbnailImageUrl,
    qty,
    color,
    sku,
    max_order_unit,
    manufacturer,
    is_in_stock,
    manufacturer_sku: [manufacturerSku]
  } = material;
  const max = max_order_unit < qty ? max_order_unit : qty;

  const isInStock = is_in_stock && Number(qty) >= Number(numberOfItemsAddedToCart);


  useEffect(() => {
    const currentItemMetadata = values.itemsMetadata[sku];
    if (currentItemMetadata != null) {
      setItemMetadata(currentItemMetadata);
    }
  }, [values.itemsMetadata]);

  const CartItemByTheme = {
    default: (
      <div className="mbw-cart-list__item">
        <div className="mbw-content">
          <div className="mbw-info">
            <p className="mbw-title">{name}</p>
            <p className="mbw-color mbw-small-text">{color}</p>
            {(qty !== '0' && values?.shippingMethod === 'overnight') && (
              <p className="mbw-ships-overnight">
                <CheckCircleIcon className="mbw-icon" />
                <p className="mbw-small-text">Ships Overnight</p>
              </p>
            )}
          </div>
          <div className="mbw-actions">
            <div className="mbw-quantity mbw-small-text">
              Qty
              <RemoveIcon
                className="mbw-quantity-minus"
                onClick={() => onDecreaseItem(sku, numberOfItemsAddedToCart)}
              />
              <span className="mbw-quantity-num">{numberOfItemsAddedToCart}</span>
              <AddIcon className="mbw-quantity-plus" onClick={() => onIncreaseItem(sku, max)} />
            </div>
            <div className="mbw-delete" onClick={() => onDeleteItem(sku, numberOfItemsAddedToCart)}>
              <img src={deleteIcon} className="mbw-delete-icon" />
              <p className="mbw-delete-text">Remove</p>
            </div>
          </div>
        </div>
        <div className="mbw-material-image-container">
          <img src={thumbnailImageUrl} className="mbw-material-image" />
        </div>
      </div>
    ),
    [themesEnum['steelcase-light']]: (
      <div
        className={`mbw-cart-list__item mbw-cart-list__item__theme__steelcase-light ${customClassName}`}
      >
        <div className="mbw-content mbw-content__theme__steelcase-light">
          <div className="mbw-content__steelcase-theme-item-info">
            <div className="mbw-info mbw-info__theme__steelcase-light">
              <p className="mbw-color mbw-small-text">{manufacturer}</p>
              <p className="mbw-title mbw-title__theme__steelcase-light">{name}</p>
              {color != null && (
                <p className="mbw-color mbw-color__theme__steelcase-light mbw-small-text">
                  {color}
                </p>
              )}
              {isInStock ? (
                <div className="mbw-ships-overnight">
                  <CheckIcon className="mbw-icon-transparent" />
                  <p className="mbw-color mbw-color__theme__steelcase-light mbw-ships-overnight-steelcase">
                    In stock · Ships overnight
                  </p>
                </div>
              ):   <p className="mbw-color mbw-color__theme__steelcase-light mbw-ships-overnight-steelcase">
                Out of stock
              </p>}
              {shouldShowPrice && (
                <p className="mbw-color mbw-color__theme__steelcase-light mbw-small-text mbw-smaller-text">
                  {`${values.initObject?.currencySymbol}${samplePrice} ${
                    CurrenciesEnum[values.initObject?.currencySymbol]
                  }`}
                </p>
              )}
            </div>
            <div className="mbw-material-image-container mbw-material-image-container__theme__steelcase-light">
              <img src={thumbnailImageUrl} className="mbw-material-image" />
            </div>
          </div>
          <div className="mbw-actions mbw-actions__theme__steelcase-light">
            {showItemCount && (
              <div className="mbw-quantity mbw-quantity__theme__steelcase-light mbw-small-text">
                <RemoveIcon
                  className="mbw-quantity-minus"
                  onClick={() => onDecreaseItem(sku, numberOfItemsAddedToCart)}
                />
                <span className="mbw-quantity-num mbw-quantity-num__theme__steelcase-light">
                  {numberOfItemsAddedToCart}
                </span>
                <AddIcon className="mbw-quantity-plus" onClick={() => onIncreaseItem(sku, max)} />
              </div>
            )}
            <div onClick={() => onDeleteItem(sku, numberOfItemsAddedToCart)}>
              <p className="mbw-delete-text mbw-delete-text__theme__steelcase-light">Remove</p>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return CartItemByTheme[values.initObject?.theme ?? 'default'];
};

export default CartItem;

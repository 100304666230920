import { canadianProvinces, usStates } from '.';

const countryStates = {
  US: {
    states: usStates,
    defaultState: usStates[0].value,
    stateLabel: 'State',
    zipCodeValidator: '^\\d{5}(-\\d{4})?$'
  },
  CA: {
    states: canadianProvinces,
    defaultState: canadianProvinces[0].value,
    stateLabel: 'Province',
    zipCodeValidator: '^[a-zA-Z]\\d[a-zA-Z] ?\\d[a-zA-Z]\\d$'
  }
};

export default countryStates;

import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import CartItemsComponent from '../cartList/cartItemsComponent';

const SamplesInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="mbw-shipping-address-cart-info mbw-shipping-address-cart-info__theme__steelcase-light">
        <div className="mbw-shipping-address-cart-info__text-container mbw-shipping-address-cart-info__text-container__theme__steelcase-light">
          <p className="mbw-shipping-address-cart-info__text">
              Samples
          </p>
        </div>
        <div className="mbw-shipping-address-cart-info__theme__steelcase-light__summary__content-line__icon">
          {isOpen ? (
            <RemoveIcon className="mbw-quantity-minus" onClick={() => setIsOpen(false)} />
          ) : (
            <AddIcon className="mbw-quantity-plus" onClick={() => setIsOpen(true)} />
          ) }
        </div>
      </div>
      {isOpen && (<CartItemsComponent itemCustomClassName="mbw-cart-list__item__theme__steelcase-light__cart-preview" />)}
    </>
  );
};

export default SamplesInfo;

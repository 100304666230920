import React from 'react';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const Select = ({
  name,
  className,
  placeholder,
  type,
  fullWidth,
  options,
  onInputChange,
  size,
  label
}) => {
  return (
    <Field name={name}>
      {({ field, form: { errors, touched } }) => {
        const { value, name, onChange, onBlur } = field;

        return (
          <TextField
            select
            variant="outlined"
            label={label}
            placeholder={placeholder}
            className={className || 'mbw-form__field'}
            value={value}
            name={name}
            onChange={(e) => {
              if (onInputChange) onInputChange();
              onChange(e);
            }}
            onBlur={onBlur}
            type={type || 'text'}
            fullWidth={fullWidth}
            size={size || 'small'}
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    </Field>
  );
};

export default Select;

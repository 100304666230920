import React, { useEffect, useState, useRef, useMemo } from "react";

const RadioSelect = ({
  onSelectNewValue,
  options = [],
  value: defaultValue = null,
}) => {
  const isInitialValueSent = useRef(false);
  const initialValue = useMemo(
    () =>
      options.map((option) => option.value).includes(defaultValue)
        ? defaultValue
        : options?.[0]?.value,
    [defaultValue, value]
  );
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (isInitialValueSent.current || !initialValue) {
      return;
    }

    onSelectNewValue(initialValue);
  }, [initialValue]);

  return (
    <div className="mbw-form__radio-select">
      {options.map((option) => {
        const isActiveValue = value === option.value;

        return (
          <div
            onClick={() => {
              onSelectNewValue?.(option.value);
              setValue(option.value);
            }}
            className={`mbw-form__radio-select__button${
              isActiveValue ? " mbw-form__radio-select__button__active" : ""
            } ${
              options.length < 2 ? "mbw-form__radio-select__button__single" : ""
            }`}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};

export default RadioSelect;

import React from 'react';
import ReactDOM from 'react-dom';

import { WindowErrorModal } from 'components';

const _MBWidgetDisplayErrorModal = data => {
  const errorModalAnchor = document.createElement('div');
  document.body.appendChild(errorModalAnchor);

  const handleUnmountAnchor = () => document.body.removeChild(errorModalAnchor);

  ReactDOM.render(
    <WindowErrorModal
      {...data}
      onUnmountAnchor={handleUnmountAnchor}
    />,
    errorModalAnchor
  );
};

export default _MBWidgetDisplayErrorModal;

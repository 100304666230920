import { createContext } from 'react';

const CartItemsContext = createContext({
  cartItems: [],
  isLoading: true,
  onClearCart: () => {},
  onSetAddedItem: () => {},
  addedItem: false,
  onDeleteItem: () => {},
  onIncreaseItem: () => {},
  onDecreaseItem: () => {},
  isProjectNameRequired: false
});

export default CartItemsContext;

import React, { useContext, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { values } from 'storage';
import { NavigationContext, CartItemsContext } from 'context';
import themesEnum from 'enums/themesEnum';

const CartOrderHeader = () => {
  const { onClose } = useContext(NavigationContext);
  const { onSetAddedItem, addedItem } = useContext(CartItemsContext);
  const CartOrderContentByTheme = {
    default: (
      <div className="cart-order-header">
      {addedItem ? (
        <div className="cart-order-header__content cart-order-header__content-fade-out">
          <CheckCircleIcon className="cart-order-header__content-icon" />
          <p>1 item added to your cart</p>
        </div>
      ) : (
        <div className="cart-order-header__content cart-order-header__content-fade-in">
          <p className="cart-order-header__content-title">
            Shopping Cart
          </p>
          ({values.numberOfItemsAddedToCart || 0})
        </div>
      )}
      <CloseIcon className="mbw-cart-popover-content__header-close" onClick={onClose} />
    </div>
    ),
    [themesEnum['steelcase-light']]: (
      <div className="cart-order-header cart-order-header__theme__steelcase-light">
      {addedItem ? (
        <div className="cart-order-header__content cart-order-header__content-fade-out">
          <CheckCircleIcon className="cart-order-header__content-icon" />
          <p>1 item added to your cart</p>
        </div>
      ) : (
        <div className="cart-order-header__content cart-order-header__content-fade-in">
          <p className="cart-order-header__content-title cart-order-header__content-title__theme__steelcase-light">
            Samples
          </p>
        </div>
      )}
      <CloseIcon className="mbw-cart-popover-content__header-close mbw-cart-popover-content__header-close__theme__steelcase-light" onClick={onClose} />
    </div>
    ),
  }

  useEffect(() => {
    setTimeout(() => {
      onSetAddedItem(false);
    }, 3300);
  }, []);

  return CartOrderContentByTheme[values.initObject?.theme ?? 'default'];
};

export default CartOrderHeader;

import { values } from "storage";

export const getDeliveryDate = () => {
  const theme = values?.initObject?.theme || 'default';
  const d = new Date();

  const dayOfWeek = d.getDay();
  const hourOfDay = d.getHours();

  const orderDate = new Date(d);

  const weekDay = dayOfWeek >= 1 && dayOfWeek <= 5;
  const sundayOrEarlySaturday = dayOfWeek === 0 || (dayOfWeek === 6 && hourOfDay < 20);
  const lateSaturday = dayOfWeek === 6 && hourOfDay >= 20;

  if (weekDay) {
    orderDate.setDate(orderDate.getDate() + 1);
  } else if (sundayOrEarlySaturday) {
    orderDate.setDate(orderDate.getDate() + 2);
  } else if (lateSaturday) {
    orderDate.setDate(orderDate.getDate() + 3);
  }

  const orderYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(orderDate);
  const orderMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(orderDate);
  let orderDay = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(orderDate);
  const orderDayOfWeek = orderDate.getDay();
  const orderMonthOfYear = orderDate.getMonth();

  const decimalDayNum = parseInt(orderDay / 10);

  if (theme === 'default') {
    // check to see what is the last digit of the day in order to set the correct termination
    if (orderDay % 10 === 1 && decimalDayNum !== 1) {
      orderDay += 'st';
    } else if (orderDay % 10 === 2 && decimalDayNum !== 1) {
      orderDay += 'nd';
    } else if (orderDay % 10 === 3 && decimalDayNum !== 1) {
      orderDay += 'rd';
    } else {
      orderDay += 'th';
    }
  }

  return { orderDate, orderDay, orderDayOfWeek, orderMonth, orderMonthOfYear, orderYear };
}
import React, { useContext } from 'react';
import classnames from 'classnames';

import { CartItemsContext } from 'context';
import { values } from 'storage';
import { PoweredBy, SimpleButton } from 'components';

import CartItemSkeleton from './cartItemSkeleton';
import CartItemsComponent from './cartItemsComponent';

const CartList = ({ textButton, onClickButton, small, buttonLoading }) => {
  const { cartItems, isLoading } = useContext(CartItemsContext);
  const theme = values?.initObject?.theme || 'default';
  const orderButtonClassName = `mbw-button mbw-button__theme__${theme}`;
  const containerClassName = `mbw-cart-list__theme__${theme}`;

  const itemNumDiff = values.cartLength - cartItems.length;

  const renderSkeletons = (skeletonNum) => {
    const skeletons = [];
    for (let i = 0; i < skeletonNum; i++) {
      skeletons.push(<CartItemSkeleton key={i} />);
    }
    return skeletons;
  };

  if (!isLoading && !values.cartLength) {
    return (
      <>
        <b className="mbw-centered-container mbw-cart-list mbw-cart-list__no-items">
          No items added to the cart.
        </b>
        <div className="mbw-footer">
          <div className="mbw-footer__container">
            <PoweredBy />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={classnames('mbw-cart-list',
          containerClassName,
          {
            'mbw-cart-list__small': small
          }
        )}
      >
        <CartItemsComponent />
        {renderSkeletons(itemNumDiff)}
      </div>
      <div className="mbw-footer">
        <div className="mbw-footer__container">
          <SimpleButton
            className={orderButtonClassName}
            onClick={onClickButton}
            text={textButton}
            loading={buttonLoading}
          />
          <PoweredBy containerClassName='mbw-footer__powered-by' />
        </div>
      </div>
    </>
  );
};

export default CartList;

import React from 'react';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';

const Input = ({ name, className, placeholder, type, label, fullWidth, size, inputProps }) => {
  return (
    <Field name={name}>
      {({ field, form: { errors, touched } }) => {
        const { value, name, onChange, onBlur } = field;

        return (
          <TextField
            variant="outlined"
            label={label}
            placeholder={placeholder}
            className={className || 'mbw-form__field'}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            type={type || 'text'}
            error={errors[name] && touched[name]}
            fullWidth={fullWidth}
            size={size || 'small'}
            inputProps={inputProps}
          />
        );
      }}
    </Field>
  );
};

export default Input;

import React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { values } from 'storage';
import themesEnum from 'enums/themesEnum';

const CartButton = ({ onOpenCart }) => {
  const isButtonFixed = values.initObject?.anchorElementId == null;
  const buttonClassName = `mbw-cart-button ${isButtonFixed ? 'mbw-cart-button__fixed' : ''}`;
  const CartButtonContentByTheme = {
    default: (
      <div
        id="mbw-cart-button"
        className={buttonClassName}
        onClick={onOpenCart}
      >
        <ShoppingCartIcon className="mbw-cart-button__icon" />
        <div className="mbw-cart-button__text">
          Your Samples
        </div>
        <div className="mbw-cart-button__items-added-counter">
          {values.numberOfItemsAddedToCart || 0}
        </div>
      </div>
    ),
    [themesEnum['steelcase-light']]: (
      <div
        id="mbw-cart-button"
        className={buttonClassName}
        onClick={onOpenCart}
      >
        <img
          src="https://material-desk-image-storageadmin-prod.s3.us-west-2.amazonaws.com/public/Samples.png"
          className="mbw-cart-button__icon__theme__steelcase-light__samples-icon"
        />
        <div className="mbw-cart-button__items-added-counter mbw-cart-button__items-added-counter__theme__steelcase-light">
          {values.numberOfItemsAddedToCart || 0}
        </div>
        <div className="mbw-cart-button__text mbw-cart-button__text__theme__steelcase-light">
          Samples
        </div>
      </div>
    )
  };

  return CartButtonContentByTheme[values.initObject?.theme || 'default'];
};

export default CartButton;

export default {
  cartItems: '_MBWidgetCartItems',
  numberOfItemsAddedToCart: '_MBWidgetNumberOfItemsAddedToCart',
  uniqueNumOfItemsAddedToCart: '_MBWidgetUniqueNumOfItemsAddedToCart',
  shippingAddress: '_MBWidgetShippingAddress',
  itemsMetadata: '_MBItemsMetadata',
  initObject: 'initObject',
  extraMetadata: 'extraMetadata',
  shippingMethod: 'shippingMethod'
};

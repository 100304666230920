import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

const CartItemSkeleton = () => {
  return (
    <div className="mbw-cart-list__item">
      <div className="mbw-cart-list__skeleton-content">
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
        <br />
        <Typography variant="body2">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </div>
      <div className="mbw-cart-list__skeleton-icon" />
    </div>
  );
};

export default CartItemSkeleton;

import {
  AddressConfirmationModal,
  Header,
  ShippingAddressCartInfo,
  ShippingAddressForm,
} from "components";
import { NavigationContext, ShippingAddressContext } from "context";
import { CartScreensEnum } from "enums";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import SmartyStreetsSDK from "smartystreets-javascript-sdk";
import { actions, values as storageValues } from "storage";
import { countryStates } from "utils";
import * as yup from "yup";
import Fade from "@material-ui/core/Fade";
import themesEnum from "enums/themesEnum";

const SmartyStreetsCore = SmartyStreetsSDK.core;
const { Lookup } = SmartyStreetsSDK.usStreet;

const key = process.env.SMARTY_WEBSITE_KEY;
const credentials = new SmartyStreetsCore.SharedCredentials(key);

const client = SmartyStreetsCore.buildClient.usStreet(credentials);

const addressInvalidMatchCode = ["N", "", null];

const commonFields = {
  firstName: yup.string().required("The first name is required").max(15),
  lastName: yup.string().required("The last name is required").max(15),
  email: yup
    .string()
    .email("Not a valid email")
    .required("The email is required"),
  phoneNumber: yup.string().required("The phone number is required"),
  country: yup.string().required("The country is required"),
  city: yup.string().required("The city is required"),
  state: yup.string().required("The state is required"),
  address1: yup.string().required("The address is required"),
  address2: yup.string(),
  requestedBy: yup.string().email("Not a valid email"),
  altCostCenter: yup.string(),
};

const headerTitleByTheme = {
  default: "Check Out",
  [themesEnum["steelcase-light"]]: "Checkout",
};

const subtitleByTheme = {
  default: null,
  [themesEnum["steelcase-light"]]: "Step 1 of 2",
};

const EditAddress = () => {
  const { address, setAddress } = useContext(ShippingAddressContext);
  const { onSetScreen, onClose } = useContext(NavigationContext);

  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(true);
  const theme = storageValues?.initObject?.theme || "default";

  const validationSchema = yup.object().shape({
    ...commonFields,
    zipCode: yup
      .string()
      .matches(
        countryStates[address.country].zipCodeValidator,
        "Is not in correct format"
      )
      .required("The zip code is required"),
  });

  const onCheckStreet = async (model) => {
    const { address1, address2, city, state, country, zipCode } = model;

    if (country !== "US") {
      return false;
    }

    const lookup = new Lookup();

    lookup.street = address1;
    lookup.secondary = address2;
    lookup.city = city;
    lookup.state = state;
    lookup.zipCode = zipCode;
    lookup.maxCandidates = 5;

    const batch = new SmartyStreetsCore.Batch();
    batch.add(lookup);

    try {
      const { lookups = [{}] } = await client.send(batch);
      const { result } = lookups[0];

      if (!(result != null && result.length > 0)) {
        return false;
      }

      const isValid = Boolean(
        result.find(
          ({ analysis }) =>
            !addressInvalidMatchCode.includes(analysis.dpvMatchCode)
        )
      );

      if (isValid) {
        const { deliveryLine1, components } = result[0];
        const { cityName, state, zipCode } = components;
        address.address1 = deliveryLine1;
        address.city = cityName;
        address.state = state;
        address.zipCode = zipCode;
      }

      return isValid;
    } catch (e) {
      console.log(e);
    }
  };

  const onSwitchScreen = () => {
    setTimeout(() => {
      onSetScreen(CartScreensEnum.CART_OVERVIEW);
    }, 450);
  };

  const onSubmit = async (values) => {
    const isValidAddress = await onCheckStreet(values);
    const { requestedBy, altCostCenter } = values;
    const shippingMethod = values.shipping;

    actions.saveMetadata({ requestedBy, altCostCenter });
    actions.saveShippingMethod(shippingMethod);

    if (isValidAddress) {
      actions.saveAddress(address);
      setVisible(false);
      onSwitchScreen();
    } else {
      setShowModal(true);
    }
  };

  const onSaveAddress = () => {
    setShowModal(false);
    setTimeout(() => {
      actions.saveAddress(address);
      onSwitchScreen();
    }, 350);
  };

  return (
    <div>
      <Formik
        initialValues={address}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values }) => {
          setAddress(values);
          return (
            <Fade in={visible} timeout={450}>
              <div className="mbw-step-container">
                <Header
                  title={headerTitleByTheme[theme]}
                  subtitle={subtitleByTheme[theme]}
                  onClose={onClose}
                />
                <ShippingAddressCartInfo />
                <ShippingAddressForm onSubmit={handleSubmit} values={values} />
              </div>
            </Fade>
          );
        }}
      </Formik>
      <AddressConfirmationModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSaveAddress={onSaveAddress}
      />
    </div>
  );
};

export default EditAddress;

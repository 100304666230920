import React from 'react';
import { CartScreensEnum } from 'enums';

import { CartOverview, EditAddress, ThankYou, CartOrder } from 'containers';

const { CART_OVERVIEW, EDIT_ADDRESS, THANK_YOU, CART_ORDER } = CartScreensEnum;

const Stack = {
  [CART_ORDER]: CartOrder,
  [EDIT_ADDRESS]: EditAddress,
  [CART_OVERVIEW]: CartOverview,
  [THANK_YOU]: ThankYou
};

const ScreenNavigator = ({ screen, props }) => {
  const ScreenComponent = Stack[screen];

  return <ScreenComponent {...props} />;
};

export default ScreenNavigator;

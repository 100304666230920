import React from 'react';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import PhoneNumber from 'awesome-phonenumber';

const PhoneNumberInput = ({ name, className, placeholder, type, label, fullWidth, size }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { value, name, onBlur } = field;
        const { errors, touched, values, setFieldValue } = form;

        const onChangePhone = e => {
          const { value } = e.target;
          // must get just the numbers from the string in order to format them correctly
          const formattedValue = value.replace(/\D/g, '');

          const pn = PhoneNumber.getAsYouType(values.country);

          for (let i = 0; i < formattedValue.length; i++) {
            pn.addChar(formattedValue[i]);
          }

          setFieldValue(name, pn.a);
        };

        return (
          <TextField
            variant="outlined"
            label={label}
            placeholder={placeholder}
            className={className || 'mbw-form__field'}
            value={value}
            name={name}
            onChange={onChangePhone}
            onBlur={onBlur}
            type={type || 'text'}
            error={errors[name] && touched[name]}
            fullWidth={fullWidth}
            size={size || 'small'}
          />
        );
      }}
    </Field>
  );
};

export default PhoneNumberInput;

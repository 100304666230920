import React, { useEffect, useRef, useState } from 'react';
import { createGenerateClassName, StylesProvider } from '@material-ui/styles';

import { CartApiCalls } from './api';
import { CartButton, CartPopover } from './components';
import { CartItemsContext, ShippingAddressContextProvider } from './context';
import { actions, values } from './storage';

function App () {
  const [showCart, setShowCart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addedItem, setAddedItem] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(0);
  const [isProjectNameRequired, setIsProjectNameRequired] = useState(false);

  const localStorageCart = useRef(values.cartItems || {});
  const itemsAddedToCart = useRef(values.numberOfItemsAddedToCart || 0);
  const cartItems = useRef([]);
  const anchorEl = useRef(null);

  useEffect(() => {
    // initialize globals
    window.addToMBCart = addToMBCart;
  }, []);

  useEffect(() => {
    document.addEventListener('_MBWidgetCustomSetItem', updateCartItems, false);

    return () =>
      document.removeEventListener('_MBWidgetCustomSetItem', () => {});
  }, []);

  useEffect(() => {
    anchorEl.current = document.getElementById('mbw-cart-button');

    const fetchCartItemsData = async () => {
      const { isOk, data } = await CartApiCalls.getLocalStorageCartItems();

      if (isOk) cartItems.current = data;

      setIsLoading(false);
    };

    fetchCartItemsData();

    if (values.address && values.address.country === 'USA') {
      const newAddress = { ...values.address, country: 'US' };
      actions.saveAddress(newAddress);
    }
  }, []);

  useEffect(() => {
    const getBrandConfiguration = async () => {
      const { isOk, data } = await CartApiCalls.getBrandConfiguration();

      if (isOk) {
        setIsProjectNameRequired(data.setProjectName);
      };
    };

    getBrandConfiguration();
  }, []);

  async function addToMBCart (sku, itemMetadata = null) {
    const { isOk, data } = await window['cart-widget']._MBWidgetCart(sku, itemMetadata);
    if (!isOk) {
      window['cart-widget']._MBWidgetDisplayErrorModal(data);
    }
  }

  const updateCartItems = async (e) => {
    const storedCartItems = JSON.parse(e.value);

    setIsLoading(true);
    setShowCart(true);
    setAddedItem(true);

    const storedCartItemsKeys = Object.keys(storedCartItems || {});
    const localStorageCartKeys = Object.keys(localStorageCart.current || {});

    const difference = storedCartItemsKeys.filter(
      (sku) => !localStorageCartKeys.includes(sku)
    );
    let itemUpdatedSuccessfuly = true;

    if (difference && difference.length) {
      const { isOk, data } = await CartApiCalls.getCartItems(difference);

      if (isOk) {
        cartItems.current = [...cartItems.current, ...data];
        itemsAddedToCart.current++;
      } else {
        // notification that something went wrong while adding an item to the cart
        itemUpdatedSuccessfuly = false;
      }
    } else {
      const updatedSku = storedCartItemsKeys.find(
        (sku) => localStorageCart.current[sku] !== storedCartItems[sku]
      );
      const diffNumOfItems =
        storedCartItems[updatedSku] - localStorageCart.current[updatedSku];

      const updatedCart = cartItems.current.map(
        ({ sku, numberOfItemsAddedToCart, material }) => {
          const maxOrderUnit = material.max_order_unit || 3;
          itemUpdatedSuccessfuly =
            sku === updatedSku && numberOfItemsAddedToCart < maxOrderUnit;

          return {
            material,
            sku,
            numberOfItemsAddedToCart:
              numberOfItemsAddedToCart +
              (itemUpdatedSuccessfuly ? diffNumOfItems : 0)
          };
        }
      );

      if (itemUpdatedSuccessfuly) {
        cartItems.current = updatedCart;
        itemsAddedToCart.current += diffNumOfItems;
      } else {
        const cartItems = {
          ...values.cartItems,
          [updatedSku]: values.cartItems[updatedSku] - 1
        };

        actions.saveCartItems(cartItems);
        actions.saveNumberOfItemsAddedToCart(
          values.numberOfItemsAddedToCart - 1
        );
      }
    }

    if (itemUpdatedSuccessfuly) {
      localStorageCart.current = storedCartItems;
    } else {
      setAddedItem(false);
    }

    setIsLoading(false);
  };

  const onClearCart = () => {
    localStorageCart.current = {};
    itemsAddedToCart.current = 0;
    cartItems.current = [];
    actions.removeCartItems();
  };

  const onDeleteItem = (sku, qty) => {
    const updatedCartItems = cartItems.current.filter(
      (item) => item.sku !== sku
    );

    delete localStorageCart.current[sku];
    itemsAddedToCart.current -= qty;
    cartItems.current = updatedCartItems;
    actions.deleteCartItem(sku, qty);

    setShouldUpdate(shouldUpdate + 1);
  };

  const onIncreaseItem = async (sku, max) => {
    const item = cartItems.current.find((item) => item.sku === sku);
    if (
      item.numberOfItemsAddedToCart >= max ||
      (!max && item.numberOfItemsAddedToCart >= 3)
    ) {
      return window['cart-widget']._MBWidgetDisplayErrorModal({ title: 'Max number of items reached', message: `You can't order more than ${max} items of the same sample` });
    }
    const updatedCartItems = cartItems.current.map((item) => {
      if (item.sku === sku) {
        item.numberOfItemsAddedToCart++;
      }

      return item;
    });

    localStorageCart.current[sku] = localStorageCart.current[sku] + 1;
    cartItems.current = updatedCartItems;
    itemsAddedToCart.current = itemsAddedToCart.current + 1;
    await actions.increaseCartItem(sku);

    setShouldUpdate(shouldUpdate + 1);
  };

  const onDecreaseItem = (sku, qty) => {
    if (qty === 1) return;

    const updatedCartItems = cartItems.current.map((item) => {
      if (item.sku === sku) {
        item.numberOfItemsAddedToCart--;
      }

      return item;
    });

    localStorageCart.current[sku]--;
    cartItems.current = updatedCartItems;
    itemsAddedToCart.current--;
    actions.decreaseCartItem(sku);

    setShouldUpdate(shouldUpdate + 1);
  };

  const cartItemsOptions = {
    cartItems: cartItems.current,
    isLoading,
    onDeleteItem,
    onIncreaseItem,
    onDecreaseItem,
    addedItem,
    onSetAddedItem: setAddedItem,
    onClearCart,
    isProjectNameRequired
  };

  const generateClassName = createGenerateClassName({
    seed: 'cart-widget'
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <CartItemsContext.Provider value={cartItemsOptions}>
        <ShippingAddressContextProvider>
          <div className="mb-widget-cart">
            <CartButton onOpenCart={() => setShowCart(true)} />
            <CartPopover
              open={showCart}
              onClose={() => setShowCart(false)}
              anchorEl={anchorEl.current}
            />
          </div>
        </ShippingAddressContextProvider>
      </CartItemsContext.Provider>
    </StylesProvider>
  );
}

export default App;

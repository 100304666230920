import React, { useContext } from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';

import { ShippingAddressContext } from 'context';
import { countryStates } from 'utils';

const AddressConfirmationModal = ({ onClose, open, onSaveAddress }) => {
  const { address } = useContext(ShippingAddressContext);
  const { address1, address2, zipCode, state: stateValue, city, country } = address;

  const { states } = countryStates[country];

  const { label: stateLabel } = states.find(({ value }) => value === stateValue);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="mbw-address-confirmation__modal"
    >
      <Fade in={open} timeout={350}>
        <div className="mbw-modal mbw-address-confirmation">
          <CloseIcon
            className="mbw-address-confirmation__icon"
            onClick={onClose}
          />
          <div className="mbw-address-confirmation__wrapper">
            <div className="mbw-address-confirmation__header">
              We are unable to validate your address, please confirm it is accurate
            </div>
            <div className="mbw-address-confirmation__content">
              <p className="mbw-address-confirmation__address-header">You Entered</p>
              <p>{address1} {address2}</p>
              <p>{city} {stateLabel} {zipCode}</p>
            </div>
            <div className="mbw-address-confirmation__buttons">
              <div className="mbw-button" onClick={onSaveAddress}>
                Keep My Address
              </div>
              <div className="mbw-light-button" onClick={onClose}>
                Update My Address
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddressConfirmationModal;

function customSetItem (key, value) {
  const event = new Event('_MBWidgetCustomSetItem');

  event.value = value;
  event.key = key;

  localStorage.setItem.apply(localStorage, arguments);

  document.dispatchEvent(event);
};

export default customSetItem;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const SimpleButton = ({ className, onClick, text, loading }) => {
  return (
    <div
      className={className}
      onClick={loading ? null : onClick}
    >
      {loading ? <CircularProgress color='#fff' size='1rem' /> : text}
    </div>
  );
};

export default SimpleButton;

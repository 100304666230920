import React from "react";

import {
  Input,
  Select,
  PoweredBy,
  AutocompleteAddress,
  PhoneNumberInput,
  SimpleButton,
} from "components";
import { countries, countryStates } from "utils";
import themesEnum from "enums/themesEnum";
import { values as storageValues } from "storage";

const commonInputProps = {
  fullWidth: true,
  className: "mbw-form__row mbw-form__field",
  inputProps: {
    maxLength: 50,
  },
};

const headerTextByTheme = {
  default: "Shipping Information",
  [themesEnum["steelcase-light"]]: "Shipping",
};

const ShippingAddressForm = ({ onSubmit, values }) => {
  const { states, defaultState, stateLabel } = countryStates[
    values.country
  ] || [{}];
  if (!values.state) values.state = defaultState;

  const handleDeleteState = () => {
    values.state = "";
    values.phoneNumber = "";
  };
  const handleDeleteAddress = () => {
    values.address1 = "";
    values.address2 = "";
    values.city = "";
    values.zipCode = "";
  };
  const theme = storageValues?.initObject?.theme || "default";
  const NameInputsByTheme = {
    default: (
      <div className="mbw-form__row">
        <Input name="firstName" placeholder="First Name" />
        <Input name="lastName" placeholder="Last Name" />
      </div>
    ),
    [themesEnum["steelcase-light"]]: (
      <>
        <Input
          name="firstName"
          placeholder="First Name"
          {...commonInputProps}
        />
        <Input name="lastName" placeholder="Last Name" {...commonInputProps} />
      </>
    ),
  };
  const SelectContentByTheme = {
    default: (
      <div className="mbw-form__row">
        <Select
          name="country"
          options={countries}
          onInputChange={() => {
            handleDeleteState();
            handleDeleteAddress();
          }}
          label="Country"
        />
        <Select
          name="state"
          options={states}
          label={stateLabel}
          onInputChange={handleDeleteAddress}
        />
      </div>
    ),
    [themesEnum["steelcase-light"]]: (
      <div className="mbw-form__row">
        <Select
          name="country"
          options={countries}
          placeholder={countries[0].label}
          onInputChange={() => {
            handleDeleteState();
            handleDeleteAddress();
          }}
        />
        <Select
          name="state"
          options={states}
          placeholder={"State"}
          onInputChange={handleDeleteAddress}
        />
      </div>
    ),
  };

  return (
    <>
      <div
        className={`mbw-shipping-address-form mbw-shipping-address-form__theme__${theme}`}
      >
        <div
          className={`mbw-shipping-address-header mbw-shipping-address-header__theme__${theme}`}
        >
          {headerTextByTheme[theme]}
        </div>
        {NameInputsByTheme[theme]}
        <Input
          name="email"
          placeholder="Email – Order Confirmation"
          {...commonInputProps}
        />
        <PhoneNumberInput
          name="phoneNumber"
          placeholder="Phone Number"
          {...commonInputProps}
        />
        {SelectContentByTheme[theme]}
        {values.country === "US" ? (
          <AutocompleteAddress
            name="address1"
            placeholder="Address 1"
            {...commonInputProps}
          />
        ) : (
          <Input
            name="address1"
            placeholder="Address 1"
            {...commonInputProps}
          />
        )}
        <Input name="address2" placeholder="Address 2" {...commonInputProps} />
        <div className="mbw-form__row">
          <Input name="city" placeholder="City" />
          <Input name="zipCode" placeholder="Zip Code" />
        </div>
        <Input
          name="requestedBy"
          placeholder="Requested by Email"
          {...commonInputProps}
        />
        {storageValues?.initObject?.withAltCostEnabled && (
          <Input
            name="altCostCenter"
            placeholder="Alt Cost Center"
            {...commonInputProps}
            inputProps={{ maxLength: 6 }}
          />
        )}
      </div>
      <div className="mbw-footer">
        <div
          className={`mbw-footer__container mbw-footer__container__theme__${theme}`}
        >
          <SimpleButton
            className={`mbw-button mbw-button__theme__${theme}`}
            onClick={onSubmit}
            text="Save And Continue"
          />
          <PoweredBy containerClassName="mbw-footer__powered-by" />
        </div>
      </div>
    </>
  );
};

export default ShippingAddressForm;

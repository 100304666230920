import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';

import { SimpleButton } from 'components';

const ErrorModal = ({ onCloseModal, message, title }) => {
  const [showAnimation, setShowAnimation] = useState(false);

  const handleCloseModal = () => {
    document.body.style.overflow = 'unset';
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
      onCloseModal();
    }, 1000);
  };

  return (
    <Modal
      open
      onClose={handleCloseModal}
      id="mbw-error-modal"
      className="mbw-error-message__modal"
    >
      <div className={classnames('mbw-error-message mbw-modal', {
        'mbw-error-message__fade-out': showAnimation
      })}>
        <div className="mbw-error-message__icon-container">
          <CloseIcon
            className="mbw-error-message__icon"
            onClick={handleCloseModal}
          />
        </div>
        <p className="mbw-error-message__text">
          <b>{title}</b>
        </p>
        <p className="mbw-error-message__text">
          {message}
        </p>
        <div className="mbw-error-message__button-container">
          <SimpleButton
            className='mbw-error-message__button mbw-button'
            onClick={handleCloseModal}
            text='Close'
          />
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;

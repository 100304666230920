import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { actions, values } from 'storage';
import { _MBWidgetCart, _MBWidgetDisplayErrorModal } from 'utils';

import App from './App';


function renderApp () {
  const widgetAnchor = document.createElement('div');
  const anchorElementId = values.initObject?.anchorElementId;
  const anchorEl = document.getElementById(values.initObject?.anchorElementId);

  if (anchorElementId == null) {
    document.body.appendChild(widgetAnchor);
  } else if (anchorElementId != null && anchorEl == null) {
    console.error(`${anchorElementId} is not an id of a node element`);
  } else {
    anchorEl.appendChild(widgetAnchor);
  }
  ReactDOM.render(<App />, widgetAnchor);
}


window.addEventListener('onAppInitialized', renderApp);

export default {
  _MBWidgetCart,
  _MBWidgetDisplayErrorModal,
  _MBWidgetSaveBrandSourceId: actions.saveBrandSourceId,
  _MBWidgetInit: actions.initializeWidget
};

import React, { useContext } from 'react';
import { CartItemsContext } from 'context';
import CartItem from './cartItem';

const CartItemsComponent = ({
  itemCustomClassName = ''
}) => {
  const { cartItems } = useContext(CartItemsContext);

  return cartItems.map(({ material = {}, numberOfItemsAddedToCart }) => (
    <CartItem
      key={material.sku}
      material={material}
      customClassName={itemCustomClassName}
      numberOfItemsAddedToCart={numberOfItemsAddedToCart}
    />
  ));
};

export default CartItemsComponent;

import React, { useRef, useState, useContext } from 'react';
import { Field } from 'formik';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';

import Options from './options';
import { useComponentVisible } from 'utils';
import { ShippingAddressContext } from 'context';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const { Lookup } = SmartyStreetsSDK.usAutocompletePro;

const key = process.env.SMARTY_WEBSITE_KEY;
const credentials = new SmartyStreetsCore.SharedCredentials(key);

const client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);

const AutocompleteAddress = ({ name, className, placeholder, type, label, fullWidth, size }) => {
  const [addresses, setAddresses] = useState([]);
  const { address } = useContext(ShippingAddressContext);
  const prevValue = useRef(address.address1);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <Field name={name}>
      {({ field, form }) => {
        const { value, name, onChange, onBlur } = field;
        const { errors, touched, setFieldValue, values } = form;

        const lookup = new Lookup(value);

        lookup.maxResults = 5;
        lookup.includeOnlyStates = [values.state];

        if (prevValue.current !== value && value !== '') {
          client.send(lookup)
            .then(({ result }) => setAddresses(result))
            .catch(e => console.log('error', e));
          prevValue.current = value;
        }

        const handleSelectAddress = ({ streetLine, city, zipcode }) => {
          setFieldValue('address1', streetLine);
          setFieldValue('city', city);
          setFieldValue('zipCode', zipcode);
          prevValue.current = streetLine;
          setIsComponentVisible(false);
        };

        return (
          <div className={classnames(className, 'mbw-autocomplete')}>
            <TextField
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              label={label}
              placeholder={placeholder}
              value={value}
              name={name}
              onChange={onChange}
              onClick={() => setIsComponentVisible(true)}
              onBlur={onBlur}
              type={type || 'text'}
              error={errors[name] && touched[name]}
              fullWidth={fullWidth}
              size={size || 'small'}
              autoComplete='no'
            />
            {isComponentVisible && (
              <Options
                addresses={addresses}
                ref={ref}
                onSelectAddress={handleSelectAddress}
              />
            )}
          </div>
        );
      }}
    </Field>

  );
};

export default AutocompleteAddress;

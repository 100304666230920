import React, { forwardRef } from 'react';

const Options = forwardRef(({ onSelectAddress, addresses }, ref) => {
  return (
    <div className="mbw-autocomplete__options-wrapper" ref={ref}>
      <div className="mbw-autocomplete__options-container">
        <div className="mbw-autocomplete__options">
          {addresses.length ? (
            addresses.map((address, idx) => {
              const { streetLine, city, zipcode } = address;
              return (
                <div
                  key={idx}
                  onClick={() => onSelectAddress(address)}
                  className="mbw-autocomplete__option"
                >
                  {streetLine}, {city} ({zipcode})
                </div>
              );
            })) : null}
        </div>
      </div>
    </div>
  );
});

export default Options;

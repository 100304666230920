import { getMaterialsForSku } from 'api/cartApiCalls';

const checkIfSkuIsValid = async (sku) => {
  try{
  // This is a quick hack so that we avoid fetching samples from other manufacturers for Steelcase
  const data = await getMaterialsForSku(sku)
    const results = data.results.filter((result) => result.sku === sku || result.manufacturer_sku.includes(sku));

    return {
      data: {
        ...data,
        results
      },
      isOk: true
    };
  } catch (e) {
    return {
      data: e,
      isOk: false
    };
  }
};

const getManufacturerSku = async (materialSku) => {
  try {
    const { results } = await getMaterialsForSku(materialSku);

    const manufacturerSku = results?.find((item) => item.type_id === "simple")?.manufacturer_sku[0];
    return {
      isOk: results?.length > 0,
      data: manufacturerSku
    };
  } catch (e) {
    return {
      data: e,
      isOk: false
    };
  }
};


export default {
  checkIfSkuIsValid,
  getManufacturerSku,
};

import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';

import { NavigationContext } from 'context';
import { ScreenNavigator } from 'navigation';
import { CartScreensEnum } from 'enums';
import { values } from 'storage';
import themesEnum from 'enums/themesEnum';

const { CART_ORDER } = CartScreensEnum;

const popoverPropsByTheme = {
  [themesEnum.default]: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    }
  },
  [themesEnum['steelcase-light']]: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    }
  }
};

const CartPopover = ({ onClose, anchorEl, open }) => {
  const [screen, setScreen] = useState(CART_ORDER);
  const [screenProps, setScreenProps] = useState({});
  const popoverClassname = `mb-widget-cart-popover mb-widget-cart-popover__theme__${values.initObject?.theme}`;

  const onClosePopover = () => {
    onClose();
    // the popover animation of opening / closing takes 315ms
    setTimeout(() => {
      setScreen(CART_ORDER);
      setScreenProps({});
    }, 315);
  };

  const navigationOptions = {
    onSetScreen: screen => {
      setScreenProps({});
      setScreen(screen);
    },
    onSetScreenProps: setScreenProps,
    onClose: onClosePopover
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClosePopover}
      anchorOrigin={popoverPropsByTheme[values.initObject?.theme].anchorOrigin}
      transformOrigin={popoverPropsByTheme[values.initObject?.theme].transformOrigin}
      className={popoverClassname}
    >
      <div className="mbw-cart-popover-content">
        <NavigationContext.Provider value={navigationOptions}>
          <ScreenNavigator screen={screen} props={screenProps} />
        </NavigationContext.Provider>
      </div>
    </Popover>
  );
};

export default CartPopover;

import { LocalStorageEnum } from 'enums';

const values = {
  numberOfItemsAddedToCart: JSON.parse(
    localStorage.getItem(LocalStorageEnum.numberOfItemsAddedToCart)
  ),

  cartItems: JSON.parse(localStorage.getItem(LocalStorageEnum.cartItems)),

  itemsMetadata: JSON.parse(localStorage.getItem(LocalStorageEnum.itemsMetadata)) || {},

  address: JSON.parse(localStorage.getItem(LocalStorageEnum.shippingAddress)),

  shippingMethod: JSON.parse(localStorage.getItem(LocalStorageEnum.shippingMethod)),

  cartLength: JSON.parse(
    localStorage.getItem(LocalStorageEnum.uniqueNumOfItemsAddedToCart)
  ),

  extraMetadata: JSON.parse(localStorage.getItem(LocalStorageEnum.extraMetadata)),

  initObject: JSON.parse(localStorage.getItem(LocalStorageEnum.initObject)),

  isFetchingItem: false
};

export default values;

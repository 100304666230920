import React, { useContext } from 'react';

import themesEnum from 'enums/themesEnum';
import { CartList, CartOrderHeader } from 'components';
import { NavigationContext } from 'context';
import { CartScreensEnum } from 'enums';
import { values } from 'storage';

const buttonTextByTheme = {
  default: 'Place Sample Order',
  [themesEnum['steelcase-light']]: 'Checkout'
};

const CartOrder = () => {
  const { onSetScreen } = useContext(NavigationContext);

  return (
    <div className="mbw-step-container">
      <CartOrderHeader />
      <CartList
        textButton={buttonTextByTheme[values.initObject?.theme]}
        onClickButton={() => onSetScreen(CartScreensEnum.EDIT_ADDRESS)}
      />
    </div>
  );
};

export default CartOrder;

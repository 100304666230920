
export const getMaterialData = async (mData, sku) => {
  const { results = [] } = mData;
  const firstChild = results.filter((item) => item.product_type === 'simple')?.[0] || results?.[0];

  const color = firstChild?.color?.[0] ?? firstChild?.primary_color?.[0];

  return {
    ...firstChild,
    color
  };
};

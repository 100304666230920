import React, { createContext, useState } from "react";
import { values } from "storage";

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  country: "US",
  zipCode: "",
  city: "",
  state: "",
  address1: "",
  address2: "",
  shipping: "ground",
};

export const ShippingAddressContext = createContext(INITIAL_STATE);

const ShippingAddressContextProvider = ({ children }) => {
  const [address, setAddress] = useState(values.address || INITIAL_STATE);

  const value = {
    address,
    setAddress,
  };

  return (
    <ShippingAddressContext.Provider value={value}>
      {children}
    </ShippingAddressContext.Provider>
  );
};

export default ShippingAddressContextProvider;

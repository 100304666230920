import React, { useContext, useState } from 'react';

import { values } from 'storage';
import { NavigationContext } from 'context';
import { CartScreensEnum, CurrenciesEnum } from 'enums';
import themesEnum from 'enums/themesEnum';
import SamplesInfo from './samplesInfo';
import Summary from './summary';

const CartInfo = () => {
  const { onSetScreen } = useContext(NavigationContext);
  const [isOpen, setIsOpen] = useState(false);

  const CartInfoContentByTheme = {
    default: (
      <div className="mbw-shipping-address-cart-info">
        <div className="mbw-shipping-address-cart-info__text-container">
          <p className="mbw-shipping-address-cart-info__text">
            Total Samples in Order
          </p>
          <p className="mbw-shipping-address-cart-info__items">
            {values.numberOfItemsAddedToCart || 0}
          </p>
        </div>
        <p
          className="mbw-shipping-address-cart-info__view-cart"
          onClick={() => onSetScreen(CartScreensEnum.CART_ORDER)}
        >
          View Cart
        </p>
    </div>
    ),
    [themesEnum['steelcase-light']]: (
      <>
        <SamplesInfo />
        <Summary />
      </>
    ),
  };

  return CartInfoContentByTheme[values.initObject?.theme || 'default'];
};

export default CartInfo;

import { LocalStorageEnum } from 'enums';
import customSetItem from './customSetItem';
import values from './values';

const appInitializedEvent = new Event('onAppInitialized');

const actions = {
  saveAddress: model => {
    values.address = model;
    localStorage.setItem(
      LocalStorageEnum.shippingAddress,
      JSON.stringify(model)
    );
  },
  saveMetadata: model => {
    values.extraMetadata = model;
    localStorage.setItem(
      LocalStorageEnum.metadata,
      JSON.stringify(model)
    );
  },

  saveNumberOfItemsAddedToCart: totalNumberOfItemsAddedToCart => {
    values.numberOfItemsAddedToCart = totalNumberOfItemsAddedToCart;
    localStorage.setItem(
      LocalStorageEnum.numberOfItemsAddedToCart,
      totalNumberOfItemsAddedToCart
    );
  },

  saveCartItems: cartItems => {
    values.cartItems = cartItems;
    localStorage.setItem(
      LocalStorageEnum.cartItems,
      JSON.stringify(cartItems)
    );
  },

  saveItemMetadata: (sku, metadata) => {
    const newItemsMetadata = {
      ...values.itemsMetadata,
      [sku]: metadata
    };

    values.itemsMetadata = newItemsMetadata;
    localStorage.setItem(LocalStorageEnum.itemsMetadata, JSON.stringify(newItemsMetadata));
  },

  saveCartLength: cartItems => {
    const cartLength = Object.keys(cartItems).length;

    values.cartItems = cartItems;
    values.cartLength = cartLength;
    localStorage.setItem(
      LocalStorageEnum.uniqueNumOfItemsAddedToCart,
      cartLength
    );
  },

  storeItemToLocalStorage: async (itemSku, itemMetadata = null, max = 3) => {
    let totalNumberOfItemsAddedToCart = 0;
    const cartItems = { ...values.cartItems };

    if (!cartItems) {
      cartItems[itemSku] = 1;
    } else {
      const numberOfItemsAdded = cartItems[itemSku];
      if (numberOfItemsAdded >= max) {
        return window['cart-widget']._MBWidgetDisplayErrorModal({ title: 'Max number of items reached', message: `You can't order more than ${max} items of the same sample` });
      }
      cartItems[itemSku] = numberOfItemsAdded ? numberOfItemsAdded + 1 : 1;
    }

    for (const [key, value] of Object.entries(cartItems)) {
      totalNumberOfItemsAddedToCart += value;
    }
    if (itemMetadata != null) {
      actions.saveItemMetadata(itemSku, itemMetadata);
    }

    actions.saveCartLength(cartItems);
    actions.saveNumberOfItemsAddedToCart(totalNumberOfItemsAddedToCart);
    customSetItem(LocalStorageEnum.cartItems, JSON.stringify(cartItems));
  },

  removeCartItems: () => {
    localStorage.removeItem(LocalStorageEnum.cartItems);
    localStorage.removeItem(LocalStorageEnum.uniqueNumOfItemsAddedToCart);
    localStorage.removeItem(LocalStorageEnum.numberOfItemsAddedToCart);

    values.cartItems = {};
    values.cartLength = 0;
    values.numberOfItemsAddedToCart = 0;
  },

  deleteCartItem: (sku, qty) => {
    delete values.cartItems[sku];
    values.numberOfItemsAddedToCart -= qty;
    values.cartLength--;

    localStorage.setItem(LocalStorageEnum.cartItems, JSON.stringify(values.cartItems));
    localStorage.setItem(LocalStorageEnum.numberOfItemsAddedToCart, values.numberOfItemsAddedToCart);
    localStorage.setItem(LocalStorageEnum.uniqueNumOfItemsAddedToCart, values.cartLength);
  },

  increaseCartItem: async (sku) => {
    values.cartItems[sku] += 1;
    values.numberOfItemsAddedToCart += 1;

    await localStorage.setItem(LocalStorageEnum.cartItems, JSON.stringify(values.cartItems));
    await localStorage.setItem(LocalStorageEnum.numberOfItemsAddedToCart, values.numberOfItemsAddedToCart);
  },

  decreaseCartItem: (sku) => {
    values.cartItems[sku] -= 1;
    values.numberOfItemsAddedToCart -= 1;

    localStorage.setItem(LocalStorageEnum.cartItems, JSON.stringify(values.cartItems));
    localStorage.setItem(LocalStorageEnum.numberOfItemsAddedToCart, values.numberOfItemsAddedToCart);
  },

  saveBrandSourceId: brandId => {
    const initObject = {
      brandId,
      anchorElementId: null,
      persist: true,
      theme: 'default',
      emailTemplate: 'default',
      showItemCount: true,
      showInventoryStatus: true,
      showPricing: false,
      hasProjectData: false,
      lang: 'en',
      currencySymbol: '$'
    };

    values.initObject = initObject;

    localStorage.setItem(LocalStorageEnum.initObject, JSON.stringify(initObject));
    window.dispatchEvent(appInitializedEvent);
  },

  setIsFetchingItem: (isFetching) => {
    values.isFetchingItem = Boolean(isFetching);
  },

  initializeWidget: (initObject) => {
    const {
      brandId,
      anchorElementId = null,
      persist = true,
      theme = 'default',
      emailTemplate = 'default',
      showItemCount = true,
      showInventoryStatus = true,
      showPricing = false,
      hasProjectData = false,
      lang = 'en',
      currencySymbol = '$',
      manufacturer = null,
      withAltCostEnabled = getIsAltCostEnabled()
    } = initObject;

    // Override for Steelcase
    if (['576', '581', 576, 581].includes(brandId)) {
      window.widgetOrderMetadata = {
        ...window.widgetOrderMetadata,
        cart: [
          ...(window.widgetOrderMetadata.cart || []).filter((cartItem) => !cartItem?.name?.includes('Shipping')),
          { name: 'Shipping & Handling', value: '14.99' }
        ]
      };
    }

    // Here we are passing the values with their default specifications
    values.initObject = {
      brandId,
      anchorElementId,
      persist,
      theme,
      emailTemplate,
      showItemCount,
      showInventoryStatus,
      withAltCostEnabled,
      showPricing,
      hasProjectData,
      lang,
      currencySymbol,
      manufacturer
    };
    localStorage.setItem(LocalStorageEnum.initObject, JSON.stringify(values.initObject));
    window.dispatchEvent(appInitializedEvent);
  }
};

export const getIsAltCostEnabled = () => {
  return localStorage.getItem('withAltCostEnabled') === 'true';
};

export default actions;

import React from 'react';
import ErrorModal from './errorModal';

const WindowErrorModal = ({ message, title, onUnmountAnchor }) => {
  const handleCloseModal = () => {
    const errorModal = document.getElementById('mbw-error-modal');

    onUnmountAnchor();
    document.body.removeChild(errorModal);
  };

  return (
    <ErrorModal
      onCloseModal={handleCloseModal}
      message={message}
      title={title}
    />
  );
};

export default WindowErrorModal;

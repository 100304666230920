import React, { useContext } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";

import { NavigationContext } from "context";
import { PoweredBy, SimpleButton } from "components";
import { abbreviatedDays, days } from "enums";
import themesEnum from "enums/themesEnum";
import { values } from "storage";
import { getDeliveryDate } from "utils";

const ThankYou = ({ orderItems = [] }) => {
  const { onClose } = useContext(NavigationContext);

  const itemsOutStock = Boolean(orderItems.find(({ inStock }) => !inStock));
  const theme = values?.initObject?.theme || "default";

  const { orderDay, orderDayOfWeek, orderMonth, orderYear } = getDeliveryDate();

  const ContentByTheme = {
    default: (
      <Fade in timeout={450}>
        <div className="mbw-thank-you">
          <div className="mbw-thank-you__header">
            <CloseIcon
              className="mbw-thank-you__header-close"
              onClick={onClose}
            />
            <p className="mbw-thank-you__header-title">Thank you</p>
            <p className="mbw-thank-you__header-content">
              Your order was placed successfully!
            </p>
            <p className="mbw-thank-you__header-message">
              Check your email for your order confirmation.
            </p>
          </div>
          <div className="mbw-thank-you__content">
            <p className="mbw-thank-you__content-title">Shipping Details</p>
            {values?.shippingMethod === "overnight" ? (
              <>
                <p className="mbw-thank-you__content-info">
                  Your in stock samples will ship{" "}
                  <b>FREE Fedex Priority Overnight</b> and are scheduled for
                  delivery 10:30am {days[orderDayOfWeek]}, {orderMonth}{" "}
                  {orderDay}, {orderYear}
                </p>
                {itemsOutStock && (
                  <div>
                    <div className="mbw-thank-you__divider" />
                    <p className="mbw-thank-you__content-info">
                      *Please be aware that some of your items may be{" "}
                      <b>out of stock</b>. Review your order confirmation email
                      for details
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="mbw-thank-you__content-info">
                  Your samples will ship <b>by Ground Shipping</b>
                </p>
                {itemsOutStock && (
                  <div>
                    <div className="mbw-thank-you__divider" />
                    <p className="mbw-thank-you__content-info">
                      *Please be aware that some of your items may be{" "}
                      <b>out of stock</b>. Review your order confirmation email
                      for details
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <SimpleButton
            className="mbw-button mbw-thank-you__close"
            onClick={onClose}
            text="Close"
          />
          <PoweredBy containerClassName="mbw-thank-you__powered-by" />
        </div>
      </Fade>
    ),
    [themesEnum["steelcase-light"]]: (
      <Fade in timeout={450}>
        <div className="mbw-thank-you">
          <div className="mbw-thank-you__header mbw-thank-you__header__theme__steelcase-light">
            <CloseIcon
              className="mbw-thank-you__header-close"
              onClick={onClose}
            />
            <p className="mbw-thank-you__header-title mbw-thank-you__header-title__theme__steelcase-light">
              Thanks for your order!
            </p>
            <p className="mbw-thank-you__header-content mbw-thank-you__header-content__theme__steelcase-light">
              Order confirmation and updates will be <br />
              sent to your email.
            </p>
          </div>
          <div className="mbw-thank-you__content__theme__steelcase-light">
            <img
              src="https://material-desk-image-storageadmin-prod.s3.us-west-2.amazonaws.com/public/Truck.png"
              className="mbw-thank-you__content__truck-icon"
            />
            <div>
              <p className="mbw-thank-you__content-info mbw-thank-you__content-info__theme__steelcase-light">
                Scheduled for delivery {abbreviatedDays[orderDayOfWeek]},{" "}
                {orderMonth} {orderDay}
              </p>
              <p className="mbw-thank-you__content-info__theme__steelcase-light__sub-header">
                FedEx Priority Overnight
              </p>
            </div>
          </div>
          <SimpleButton
            className="mbw-button mbw-button__theme__steelcase-light mbw-thank-you__close"
            onClick={onClose}
            text="Close"
          />
          <PoweredBy containerClassName="mbw-thank-you__powered-by" />
        </div>
      </Fade>
    ),
  };

  return ContentByTheme[theme];
};

export default ThankYou;

import React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';

const TextArea = ({ name, className, placeholder, rows }) => {
  return (
    <Field name={name}>
      {({ field, form: { errors, touched } }) => {
        const { value, name, onChange, onBlur } = field;

        return (
          <textarea
            placeholder={placeholder}
            className={classnames(className, {
              [`${className}__error`]: errors[name] && touched[name]
            })}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            rows={rows || 1}
          />
        );
      }}
    </Field>
  );
};

export default TextArea;

import React, { useContext } from 'react';
import { CurrenciesEnum } from 'enums';
import { values } from 'storage';
import { CartItemsContext } from 'context';

const Summary = () => {
  const { cartItems: apiCartItems } = useContext(CartItemsContext);
  const cartItems = values.cartItems;
  const itemsMetadata = values.itemsMetadata;
  const samplesPrice = Object.entries(cartItems).reduce((acc, [itemSku, val]) => {
    const materialApiPrice = apiCartItems.find(
      apiCartItem => apiCartItem?.material?.sku === itemSku
      )?.material?.sample_price;
    if (Object.keys(itemsMetadata).includes(itemSku) && itemsMetadata[itemSku]?.priceOverride != null) {
      const samplePrice = Number(itemsMetadata[itemSku].priceOverride) || 0; // Default value in case of NaN
      const totalSamplesOfThisTypePrice = cartItems[itemSku] * samplePrice;
      return acc + totalSamplesOfThisTypePrice;
    } else if (materialApiPrice != null) {
      return acc + (Number(materialApiPrice) || 0) * val;
    } else {
      return acc;
    }
  }, 0.00);
  const currencySymbol = values.initObject?.currencySymbol;
  const showPricing = values.initObject?.showPricing;
  const samplesPriceToShow = `${currencySymbol}${samplesPrice.toFixed(2)} ${CurrenciesEnum[currencySymbol]}`;
  const emptyPriceToShow = `${currencySymbol}0.00 ${CurrenciesEnum[currencySymbol]}`
  const cartMetadata = window.widgetOrderMetadata?.cart || null;

  const totalCartValue = cartMetadata != null ? cartMetadata.reduce((acc, currentItem) => acc + Number(currentItem.value), 0) : 0;
  const totalValue = showPricing ? totalCartValue + samplesPrice : totalCartValue;
  const totalValueToShow = `${currencySymbol}${Number(totalValue).toFixed(2)} ${CurrenciesEnum[currencySymbol]}`;

  if (!showPricing) {
    return null;
  }
    
    return (
        <div className="mbw-shipping-address-cart-info mbw-shipping-address-cart-info__theme__steelcase-light mbw-shipping-address-cart-info__theme__steelcase-light__summary">
          <div className="mbw-shipping-address-cart-info__text-container mbw-shipping-address-cart-info__text-container__theme__steelcase-light">
            <p className="mbw-shipping-address-cart-info__text">
              Summary
            </p>
          </div>
          {showPricing && (
            <div className="mbw-shipping-address-cart-info__theme__steelcase-light__summary__content-line">
              <p>
                Samples
              </p>
              <p className="mbw-shipping-address-cart-info__theme__steelcase-light__summary__content-line__text">
                {showPricing ? samplesPriceToShow : emptyPriceToShow}
              </p>
            </div>
          )}
          {cartMetadata?.map((cartItem) => (
            <div key={cartItem.name} className="mbw-shipping-address-cart-info__theme__steelcase-light__summary__content-line">
              <p>
                {cartItem.name}
              </p>
              <p className="mbw-shipping-address-cart-info__theme__steelcase-light__summary__content-line__text">
                {`${currencySymbol}${Math.abs(cartItem.value)} ${CurrenciesEnum[currencySymbol]}`}
              </p>
            </div>
          ))}
          <div className="mbw-shipping-address-cart-info__theme__steelcase-light__summary__content-line">
              <p>
                Total
              </p>
              <p className="mbw-shipping-address-cart-info__theme__steelcase-light__summary__content-line__text">
                {totalValueToShow}
              </p>
            </div>
        </div>
    );
};

export default Summary;